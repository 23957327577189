import React, { memo } from "react";
import PropTypes from "prop-types";

import { useTranslations } from "next-intl";

import BannerBase from "../BannerBase";

const CookieConsent = ({ closeCookieConsentBanner, dataTestId }) => {
  const t = useTranslations("default");

  const containerClasses =
    "md:flex-row flex-col items-center justify-start m:px-2.5 py-0 z-50 w-full text-sm";
  const contentClasses =
    "md:flex-row justify-center items-center m:pe-8 max-w-5xl";
  const textClasses = "py-3 ps-2 text-left me-32 md:me-40";
  const linkClasses = "no-underline pe-4";

  return (
    <BannerBase
      name="cookie-consent"
      text={t("banner_cookie_consent_message")}
      link={t("privacy_url")}
      linkText={t("footer_privacy_policy")}
      closeText={t("banner_cookie_consent_close")}
      onClose={closeCookieConsentBanner}
      ariaCloseText={t("banner_cookie_consent_aria_close")}
      containerClasses={containerClasses}
      contentClasses={contentClasses}
      textClasses={textClasses}
      linkClasses={linkClasses}
      backgroundColor="bg-purple-primary"
      textColor="text-white"
      linkColor="text-mint-primary"
      dataTestId={dataTestId}
    />
  );
};

CookieConsent.propTypes = {
  closeCookieConsentBanner: PropTypes.func.isRequired,
  dataTestId: PropTypes.string,
};

export default memo(CookieConsent);
