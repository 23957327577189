import React, { memo } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useTranslations } from "next-intl";

import {
  getCtaCompleted,
  selectSocialShare,
  selectScrollToDonateCompleted,
} from "../../reducers/ActionFormReducer";
import Step from "./Step";

const ProgressTracker = (props) => {
  const { dataTestId, classes, showLogo } = props;
  const layoutClasses =
    "flex flex-row justify-start items-center lg:pt-0 md:pt-0 pt-4";
  const styles = classNames(classes, layoutClasses);
  const t = useTranslations("default");

  const signed = useSelector(getCtaCompleted);
  const donated = useSelector(selectScrollToDonateCompleted);
  const { opted: shared, completed: shareCompleted } =
    useSelector(selectSocialShare);

  const containerClasses = classNames(
    "block lg:w-1/3 md:w-1/3 w-full lg:pb-0 md:pb-0 pb-8 text-sm",
    !signed ? "hidden" : ""
  );

  const steps = [
    {
      key: "progressTracker_signed",
      label: t("progressTracker_signed"),
      completed: signed,
      skipped: false,
    },
    {
      key: "progressTracker_shared",
      label: t("progressTracker_shared"),
      completed: shared != null && shareCompleted,
      skipped: shared != null && !shared,
    },
    {
      key: "progressTracker_donated",
      label: t("progressTracker_donated"),
      completed: donated,
      skipped: false,
    },
  ];

  return (
    <section className={containerClasses}>
      {showLogo && (
        <img
          src="https://champaign-assets-production.s3.us-west-2.amazonaws.com/unique/Eko_Logo_White.svg"
          alt="org-logo"
          width={100}
          height={55}
          className="ms-4 top-1 left-2 lg:hidden block"
        />
      )}
      <div data-testid={dataTestId} className={styles}>
        {steps.map(({ key, label, completed, skipped }) => (
          <Step
            completed={completed}
            skipped={skipped}
            key={key}
            dataTestId={key}
          >
            {label}
          </Step>
        ))}
      </div>
    </section>
  );
};

ProgressTracker.propTypes = {
  dataTestId: PropTypes.string,
  classes: PropTypes.string,
  showLogo: PropTypes.bool,
};

ProgressTracker.defaultProps = {
  classes: "",
  showLogo: false,
};

export default memo(ProgressTracker);
