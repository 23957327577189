import React, { memo } from "react";
import PropTypes from "prop-types";

import { useTranslations } from "next-intl";

import BannerBase from "../BannerBase";

const DoubleOptInBanner = ({ handleClose }) => {
  const t = useTranslations("default");

  const containerClasses =
    "md:flex-row flex-col items-center justify-start m:px-2.5 py-0 z-50 w-full text-lg";
  const contentClasses =
    "md:flex-row justify-center items-center m:pe-8 max-w-5xl";
  const textClasses = "py-3 ps-2 text-left me-32 md:me-40";

  return (
    <BannerBase
      name="double-opt-in"
      text={t("banner_gdprMsg")}
      closeText={t("banner_ctaMsg")}
      onClose={handleClose}
      ariaCloseText={t("banner_cookie_consent_aria_close")}
      containerClasses={containerClasses}
      contentClasses={contentClasses}
      textClasses={textClasses}
      backgroundColor="bg-purple-primary"
      textColor="text-white"
      dataTestId="double-opt-in-banner-close"
    />
  );
};

DoubleOptInBanner.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default memo(DoubleOptInBanner);
