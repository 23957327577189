import React, { memo } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import { selectIsMobile } from "../../reducers/PageReducer";

const icCheckCircle = "/assets/icGreenCheck.svg";
const icCrossCircle = "/assets/icRedCircle.svg";

const Circle = ({ dataTestId }) => {
  const isMobile = useSelector(selectIsMobile);

  return (
    <svg viewBox="0 0 25 25" width="25" heigth="25">
      <circle
        cx="50%"
        cy="50%"
        r="10"
        stroke={isMobile ? "black" : "white"}
        strokeWidth="1"
        fill="transparent"
        data-testid={`${dataTestId}.uncompleted`}
      />
    </svg>
  );
};

const Icon = ({ skipped }) => {
  const src = !skipped ? icCheckCircle : icCrossCircle;
  return (
    <img
      src={src}
      width={25}
      height={25}
      data-testid="progress-tracker-icon"
      alt=""
      aria-hidden="true"
    />
  );
};

const Step = ({ classes, dataTestId, completed, skipped, children }) => {
  const containerClasses = classNames(
    classes,
    "flex items-center px-2 transition-all"
  );
  const iconClasses = "pe-1";
  return (
    <div data-testid={dataTestId}>
      <span className={containerClasses}>
        <span className={iconClasses}>
          {completed || skipped ? (
            <Icon skipped={skipped} />
          ) : (
            <Circle dataTestId={dataTestId} />
          )}
        </span>
        <span>{children}</span>
      </span>
    </div>
  );
};

Step.propTypes = {
  classes: PropTypes.string,
  dataTestId: PropTypes.string,
  skipped: PropTypes.bool,
  completed: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Step.defaultProps = {
  classes: "",
  dataTestId: "",
  children: "",
};

export default memo(Step);
