import React, { memo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Button = ({
  onClick,
  onKeyDown,
  children,
  type,
  disabled,
  id,
  ariaLabel,
  dataTestId,
  color,
  hoverColor,
  spacingClasses,
  positionClasses,
  extraClasses,
}) => {
  const classes = classNames(
    color,
    hoverColor,
    spacingClasses,
    positionClasses,
    extraClasses,
    "cursor-pointer"
  );
  return (
    <button
      onClick={onClick}
      onKeyDown={onKeyDown}
      type={type}
      className={classes}
      disabled={disabled}
      aria-disabled={disabled}
      id={id}
      data-testid={dataTestId}
      aria-label={ariaLabel}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  extraClasses: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  ariaLabel: PropTypes.string.isRequired,
  dataTestId: PropTypes.string,
  id: PropTypes.string,
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  spacingClasses: PropTypes.string,
  positionClasses: PropTypes.string,
  extraClasses: PropTypes.string,
};

Button.defaultProps = {
  type: "button",
  extraClasses: "",
  disabled: false,
  onClick: () => undefined,
  onKeyDown: () => undefined,
  ariaLabel: "",
  dataTestId: null,
  id: undefined,
  color: "bg-purple-primary",
  hoverColor: "hover:bg-purple-hover",
  spacingClasses: "",
  positionClasses: "",
  extraClasses: "",
};

export default memo(Button);
