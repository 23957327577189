import React, { memo } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useTranslations } from "next-intl";
import Button from "../../Buttons/Button";

const BannerBase = ({
  name,
  text,
  link,
  linkText,
  closeText,
  onClose,
  ariaCloseText,
  containerClasses,
  contentClasses,
  textClasses,
  linkClasses,
  backgroundColor,
  textColor,
  linkColor,
  dataTestId,
}) => {
  const closeButtonSpacingClasses =
    "md:h-full h-8 w-full md:w-48 m-0 px-8 min-h-fit";
  const closeButtonPositionClasses = "top-0 right-0 md:absolute static";

  return (
    <div
      data-testid={name}
      className={classnames(
        "fixed",
        "flex",
        "flex-col",
        backgroundColor,
        textColor,
        !!containerClasses && containerClasses
      )}
    >
      <div
        className={classnames(
          "flex",
          "flex-row",
          !!contentClasses && contentClasses
        )}
      >
        <div
          className={classnames(!!textClasses && textClasses)}
          aria-label={text}
        >
          {text}{" "}
          {linkText && (
            <a
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              className={classnames(linkColor, !!linkClasses && linkClasses)}
            >
              {linkText}
            </a>
          )}
        </div>
      </div>
      <Button
        onClick={onClose}
        spacingClasses={closeButtonSpacingClasses}
        positionClasses={closeButtonPositionClasses}
        extraClasses="no-underline border-0 bg-mint-primary text-black"
        ariaLabel={ariaCloseText}
        dataTestId={dataTestId}
      >
        {closeText}
      </Button>
    </div>
  );
};

BannerBase.defaultProps = {
  name: "banner",
  link: null,
  linkText: null,
  closeText: "banner_close",
  containerClasses: null,
  ariaCloseText: "banner_close",
  contentClasses: null,
  textClasses: null,
  linkClasses: null,
  backgroundColor: `bg-purple-primary`,
  textColor: "text-white",
  linkColor: "text-white",
  dataTestId: "banner-close",
};

BannerBase.propTypes = {
  name: PropTypes.string,
  text: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  dataTestId: PropTypes.string.isRequired,
  link: PropTypes.string,
  linkText: PropTypes.string,
  closeText: PropTypes.string,
  ariaCloseText: PropTypes.string,
  containerClasses: PropTypes.string,
  contentClasses: PropTypes.string,
  textClasses: PropTypes.string,
  linkClasses: PropTypes.string,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  linkColor: PropTypes.string,
};

export default memo(BannerBase);
